import React, { createContext, useState, useContext, useEffect } from "react";
import Head from "next/head";
import Router from "next/router";
import decode from "jwt-decode";
import api from "../services/api";
import GridSystem from "../styles/gridSystem";
import Spacing from "../styles/spacing";
import { User } from "~/types/iam";
import Container from "~/components/Dashboard/Styles";
import DashboardHeader from "~/components/DashboardHeader";
import Spinner from "~/shared/Spinner";

export type AuthContextState = Partial<{
    isAuthenticated: boolean;
    user: Record<string, any>;
    login: (email: string, password: string, expireIn?: number) => Promise<[number, User]>;
    loading: boolean;
    logout: () => void;
    account: Record<string, any> | null;
    changeProfileToken: (token: string) => Promise<void>;
}>;

const AuthContext = createContext<AuthContextState>({});

const getCurrentUser = () => {
    if (typeof window !== "undefined") {
        const token = localStorage.getItem("fotopServicos:token");
        if (!token) {
            return null;
        }

        return decode(token) as User;
    }

    return null;
};

export const AuthProvider = ({ publicPaths, children }) => {
    const [user, setUser] = useState(null);
    const [account, setAccount] = useState<User>(getCurrentUser());
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const route = Router?.route;

        async function loadUser() {
            const token = localStorage.getItem("fotopServicos:token");

            if (token) {
                setAccount(decode(token));

                api.defaults.headers.Authorization = `Bearer ${token}`;
                try {
                    const { data: dataCustomer } = await api.get(`/v1/customer`);

                    if (dataCustomer && dataCustomer.forceLogout) {
                        logout();
                    } else if (dataCustomer) {
                        setUser(dataCustomer);
                    }
                } catch (e) {
                    logout();
                }
            }
            setLoading(false);
        }

        if (loading === true && !publicPaths.includes(route)) loadUser();
    }, [user, publicPaths]);

    const login = async (email: string, password: string, expireIn?: number) => {
        const { status, data } = await api.post("/v1/auth", { email, password, expireIn: expireIn || 300 });

        if (data.token) {
            setAccount(decode(data.token));

            localStorage.setItem("fotopServicos:token", data.token);
            api.defaults.headers.Authorization = `Bearer ${data.token}`;
            const { data: dataCustomer } = await api.get(`/v1/customer`);
            if (dataCustomer) {
                setUser(dataCustomer);
                localStorage.setItem("fotopServicos:user", JSON.stringify(dataCustomer));
            }
        }

        return [status, getCurrentUser()] as [number, User];
    };

    const changeProfileToken = async (token: string) => {
        localStorage.setItem("fotopServicos:token", token);
        api.defaults.headers.Authorization = `Bearer ${token}`;
        const { data: dataCustomer } = await api.get(`/v1/customer`);
        if (dataCustomer) {
            setUser(dataCustomer);
            setAccount(decode(token));
            localStorage.setItem("fotopServicos:user", JSON.stringify(dataCustomer));
        }
    };

    const logout = () => {
        localStorage.removeItem("fotopServicos:token");
        setUser(null);
        Router.push("/login");
    };

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated: !!user,
                user,
                login,
                loading,
                logout,
                account,
                changeProfileToken,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default function useAuth() {
    const context = useContext(AuthContext);

    return context;
}

export function ProtectRoute(
    Component: any,
    { withUrsulaoTemplating = false, title = "Fotop Serviços", mustBeOwner = false, scripts = [] } = {}
) {
    return (props: Record<string, any>) => {
        const { isAuthenticated, loading, account } = useAuth();

        useEffect(() => {
            if (!isAuthenticated && !loading) {
                Router.push({ pathname: "/login", query: { to: window.location.toString() } });
            }
        }, [loading, isAuthenticated]);

        if (loading) {
            return (
                <Container>
                    <Head>
                        <title>{title}</title>
                        {scripts.map((script, index) => (
                            <script key={`script-${index}`} src={script} />
                        ))}
                    </Head>

                    <DashboardHeader />

                    <div className="container pt-10 text-center">
                        <Spinner />
                    </div>
                </Container>
            );
        } else if (!loading && mustBeOwner && !account.organization?.owner) {
            return (
                <Container>
                    <Head>
                        <title>{title}</title>
                    </Head>

                    <DashboardHeader />

                    <div className="container text-center">
                        <p>Você não possui permissão para acessar essa área</p>
                    </div>
                </Container>
            );
        } else {
            if (!withUrsulaoTemplating) {
                return <Component {...props} />;
            }

            return (
                <GridSystem>
                    <Spacing>
                        <Component {...props} />
                    </Spacing>
                </GridSystem>
            );
        }
    };
}

import axios from "~/services/api";
import { AnyRecord } from "~/types/record";
import { LocatedJob } from "~/types/jobs";

export type CreateJobResponse = { token: string };
export type CreateJobRequest = any;

export async function createJob(data: CreateJobRequest): Promise<CreateJobResponse> {
    return axios.post("/v2/jobs", data).then((res) => res.data);
}

export type UpdateJobInPlaceContactResponse = any;
export type UpdateJobInPlaceContactRequest = { jobId: number; data: AnyRecord };

export async function updateInPlaceContact({
    jobId,
    data,
}: UpdateJobInPlaceContactRequest): Promise<UpdateJobInPlaceContactResponse> {
    return axios.patch(`/v1/jobs/${jobId}/in-place-contact`, data).then((res) => res.data);
}

export type UpdateCustomerCodeResponse = any;
export type UpdateCustomerCodeRequest = { jobId: number; data: AnyRecord };

export async function updateCustomerCode({
    jobId,
    data,
}: UpdateCustomerCodeRequest): Promise<UpdateCustomerCodeResponse> {
    return axios.patch(`/v1/jobs/${jobId}/customer-code`, data).then((res) => res.data);
}

export type UpdateAddressResponse = any;
export type UpdateAddressCodeRequest = { jobId: number; data: AnyRecord };

export async function updateAddress({ jobId, data }: UpdateAddressCodeRequest): Promise<UpdateAddressResponse> {
    return axios.patch(`/v1/jobs/${jobId}/customer-address`, data).then((res) => res.data);
}

export type UpdateObservationResponse = any;
export type UpdateObservationCodeRequest = { jobId: number; data: AnyRecord };

export async function updateObservation({
    jobId,
    data,
}: UpdateObservationCodeRequest): Promise<UpdateObservationResponse> {
    return axios.patch(`/v1/jobs/${jobId}/observation`, data).then((res) => res.data);
}

export type GetJobInfoResponse = { record: LocatedJob };
export type GetJobInfoRequest = { jobId: number; projectFiles?: boolean; };

export async function getJobInfo({ jobId, projectFiles = false }: GetJobInfoRequest): Promise<GetJobInfoResponse> {
    return axios.get(`/v1/customer/jobs/${jobId}/files?projectFiles=${projectFiles ? 1 : 0}`).then((res) => res.data);
}

export type GetDownloadUrlResponse = string;
export type GetDownloadUrlRequest = { jobId: number; fileId: number; timeout?: number; projected?: boolean };

export async function getDownloadUrl({
    jobId,
    fileId,
    timeout = 900,
    projected = false
}: GetDownloadUrlRequest): Promise<GetDownloadUrlResponse> {
    return axios
        .get(`/v2/customer/jobs/${jobId}/files/${fileId}/download-link?timeout=${timeout}&projected=${projected ? 1 : 0}`)
        .then((res) => res.data.record);
}

export type GenerateSlideshowResponse = {
    id: string;
    status: string;
    error_message?: string;
    url: string;
    title: string;
    subtitle: string;
};
export type GenerateSlideshowRequest = { jobId: number; videoSource: Record<string, any> };

export async function generateSlideshow({
    jobId,
    videoSource,
}: GenerateSlideshowRequest): Promise<GenerateSlideshowResponse> {
    return axios.post(`/v1/quality/slideshow`, { jobId, videoSource }).then((res) => res.data);
}

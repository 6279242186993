import qs from "qs";
import axios from "~/services/api";

export type PostFillAddressResponse = { regions?: string[] };
export type PostFillAddressRequest = {
    token?: string;
    cityName: string
};

export async function postAddress({ token, cityName }: PostFillAddressRequest): Promise<PostFillAddressResponse> {
    const query = token ? qs.stringify({ t: token, cityName }) : qs.stringify({ cityName });

    return axios.get(`/v1/jobs/scheduling/address/post-selection?${query}`).then(res => res.data);
};

export type ScheduleJobByTokenResponse = any;
export type SchduleJobByTokenRequest = {
    token: string;
    jobId: number;
    data: { schduleDate: string; period: string | null; observation?: string }
};

export async function scheduleJobByToken({ jobId, token, data }: SchduleJobByTokenRequest): Promise<ScheduleJobByTokenResponse> {
    const query = qs.stringify({ token });
    const path = `/v2/jobs/${jobId}/schedule-by-token?${query}`;

    return axios.patch(path, data).then(res => res.data);
}

import styled from "styled-components";

const GridSystem = styled.div`
    height: 100%;
    .h-100 {
        height: 100%;
    }

    .w-100 {
        width: 100%;
    }

    .container-fluid {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
    }

    .container {
        margin: 0 auto;
        max-width: 1200px;
    }

    .row {
        display: flex;
        flex-wrap: wrap;
    }

    .col-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    @media (min-width: 768px) {
        .col-md {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
        }

        .col-md-auto {
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;
        }

        .col-md-1 {
            flex: 0 0 8.33333%;
            max-width: 8.33333%;
        }

        .col-md-2 {
            flex: 0 0 16.66667%;
            max-width: 16.66667%;
        }

        .col-md-3 {
            flex: 0 0 25%;
            max-width: 25%;
        }

        .col-md-4 {
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
        }

        .col-md-5 {
            flex: 0 0 41.66667%;
            max-width: 41.66667%;
        }

        .col-md-6 {
            flex: 0 0 50%;
            max-width: 50%;
        }

        .col-md-7 {
            flex: 0 0 58.33333%;
            max-width: 58.33333%;
        }

        .col-md-8 {
            flex: 0 0 66.66667%;
            max-width: 66.66667%;
        }

        .col-md-9 {
            flex: 0 0 75%;
            max-width: 75%;
        }

        .col-md-10 {
            flex: 0 0 83.33333%;
            max-width: 83.33333%;
        }

        .col-md-11 {
            flex: 0 0 91.66667%;
            max-width: 91.66667%;
        }

        .col-md-12 {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .order-md-first {
            order: -1;
        }

        .order-md-last {
            order: 13;
        }

        .order-md-0 {
            order: 0;
        }

        .order-md-1 {
            order: 1;
        }

        .order-md-2 {
            order: 2;
        }

        .order-md-3 {
            order: 3;
        }

        .order-md-4 {
            order: 4;
        }

        .order-md-5 {
            order: 5;
        }

        .order-md-6 {
            order: 6;
        }

        .order-md-7 {
            order: 7;
        }

        .order-md-8 {
            order: 8;
        }

        .order-md-9 {
            order: 9;
        }

        .order-md-10 {
            order: 10;
        }

        .order-md-11 {
            order: 11;
        }

        .order-md-12 {
            order: 12;
        }

        .offset-md-0 {
            margin-left: 0;
        }

        .offset-md-1 {
            margin-left: 8.33333%;
        }

        .offset-md-2 {
            margin-left: 16.66667%;
        }

        .offset-md-3 {
            margin-left: 25%;
        }

        .offset-md-4 {
            margin-left: 33.33333%;
        }

        .offset-md-5 {
            margin-left: 41.66667%;
        }

        .offset-md-6 {
            margin-left: 50%;
        }

        .offset-md-7 {
            margin-left: 58.33333%;
        }

        .offset-md-8 {
            margin-left: 66.66667%;
        }

        .offset-md-9 {
            margin-left: 75%;
        }

        .offset-md-10 {
            margin-left: 83.33333%;
        }

        .offset-md-11 {
            margin-left: 91.66667%;
        }
    }

    @media (min-width: 992px) {
        .col-lg {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
        }

        .col-lg-auto {
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;
        }

        .col-lg-1 {
            flex: 0 0 8.33333%;
            max-width: 8.33333%;
        }

        .col-lg-2 {
            flex: 0 0 16.66667%;
            max-width: 16.66667%;
        }

        .col-lg-3 {
            flex: 0 0 25%;
            max-width: 25%;
        }

        .col-lg-4 {
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
        }

        .col-lg-5 {
            flex: 0 0 41.66667%;
            max-width: 41.66667%;
        }

        .col-lg-6 {
            flex: 0 0 50%;
            max-width: 50%;
        }

        .col-lg-7 {
            flex: 0 0 58.33333%;
            max-width: 58.33333%;
        }

        .col-lg-8 {
            flex: 0 0 66.66667%;
            max-width: 66.66667%;
        }

        .col-lg-9 {
            flex: 0 0 75%;
            max-width: 75%;
        }

        .col-lg-10 {
            flex: 0 0 83.33333%;
            max-width: 83.33333%;
        }

        .col-lg-11 {
            flex: 0 0 91.66667%;
            max-width: 91.66667%;
        }

        .col-lg-12 {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .order-lg-first {
            order: -1;
        }

        .order-lg-last {
            order: 13;
        }

        .order-lg-0 {
            order: 0;
        }

        .order-lg-1 {
            order: 1;
        }

        .order-lg-2 {
            order: 2;
        }

        .order-lg-3 {
            order: 3;
        }

        .order-lg-4 {
            order: 4;
        }

        .order-lg-5 {
            order: 5;
        }

        .order-lg-6 {
            order: 6;
        }

        .order-lg-7 {
            order: 7;
        }

        .order-lg-8 {
            order: 8;
        }

        .order-lg-9 {
            order: 9;
        }

        .order-lg-10 {
            order: 10;
        }

        .order-lg-11 {
            order: 11;
        }

        .order-lg-12 {
            order: 12;
        }

        .offset-lg-0 {
            margin-left: 0;
        }

        .offset-lg-1 {
            margin-left: 8.33333%;
        }

        .offset-lg-2 {
            margin-left: 16.66667%;
        }

        .offset-lg-3 {
            margin-left: 25%;
        }

        .offset-lg-4 {
            margin-left: 33.33333%;
        }

        .offset-lg-5 {
            margin-left: 41.66667%;
        }

        .offset-lg-6 {
            margin-left: 50%;
        }

        .offset-lg-7 {
            margin-left: 58.33333%;
        }

        .offset-lg-8 {
            margin-left: 66.66667%;
        }

        .offset-lg-9 {
            margin-left: 75%;
        }

        .offset-lg-10 {
            margin-left: 83.33333%;
        }

        .offset-lg-11 {
            margin-left: 91.66667%;
        }

        .d-lg-none {
            display: none;
        }

        .d-lg-show {
            display: flex !important;
        }
    }

    @media (min-width: 1200px) {
        .col-xl {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
        }
        .col-xl-auto {
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;
        }
        .col-xl-1 {
            flex: 0 0 8.33333%;
            max-width: 8.33333%;
        }
        .col-xl-2 {
            flex: 0 0 16.66667%;
            max-width: 16.66667%;
        }
        .col-xl-3 {
            flex: 0 0 25%;
            max-width: 25%;
        }
        .col-xl-4 {
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
        }
        .col-xl-5 {
            flex: 0 0 41.66667%;
            max-width: 41.66667%;
        }
        .col-xl-6 {
            flex: 0 0 50%;
            max-width: 50%;
        }
        .col-xl-7 {
            flex: 0 0 58.33333%;
            max-width: 58.33333%;
        }
        .col-xl-8 {
            flex: 0 0 66.66667%;
            max-width: 66.66667%;
        }
        .col-xl-9 {
            flex: 0 0 75%;
            max-width: 75%;
        }
        .col-xl-10 {
            flex: 0 0 83.33333%;
            max-width: 83.33333%;
        }
        .col-xl-11 {
            flex: 0 0 91.66667%;
            max-width: 91.66667%;
        }
        .col-xl-12 {
            flex: 0 0 100%;
            max-width: 100%;
        }
        .order-xl-first {
            order: -1;
        }
        .order-xl-last {
            order: 13;
        }
        .order-xl-0 {
            order: 0;
        }
        .order-xl-1 {
            order: 1;
        }
        .order-xl-2 {
            order: 2;
        }
        .order-xl-3 {
            order: 3;
        }
        .order-xl-4 {
            order: 4;
        }
        .order-xl-5 {
            order: 5;
        }
        .order-xl-6 {
            order: 6;
        }
        .order-xl-7 {
            order: 7;
        }
        .order-xl-8 {
            order: 8;
        }
        .order-xl-9 {
            order: 9;
        }
        .order-xl-10 {
            order: 10;
        }
        .order-xl-11 {
            order: 11;
        }
        .order-xl-12 {
            order: 12;
        }
        .offset-xl-0 {
            margin-left: 0;
        }
        .offset-xl-1 {
            margin-left: 8.33333%;
        }
        .offset-xl-2 {
            margin-left: 16.66667%;
        }
        .offset-xl-3 {
            margin-left: 25%;
        }
        .offset-xl-4 {
            margin-left: 33.33333%;
        }
        .offset-xl-5 {
            margin-left: 41.66667%;
        }
        .offset-xl-6 {
            margin-left: 50%;
        }
        .offset-xl-7 {
            margin-left: 58.33333%;
        }
        .offset-xl-8 {
            margin-left: 66.66667%;
        }
        .offset-xl-9 {
            margin-left: 75%;
        }
        .offset-xl-10 {
            margin-left: 83.33333%;
        }
        .offset-xl-11 {
            margin-left: 91.66667%;
        }
        .d-xl-none {
            display: none;
        }
    }

    .d-none {
        display: none;
    }

    .d-show {
        visibility: visible;
    }
`;

export default GridSystem;

import styled from "styled-components";

const Colors = styled.div`
    height: 100%;

    p, li, label {
        color: #5c5c5c;
    }

    .t-white {
        color: #fff !important;
    }

    .t-yellow {
        color: #fbff21 !important;
    }

    .t-orange {
        color: #fe8418 !important;
    }

    .t-green {
        color: #166969 !important;
    }

    .t-blue {
        color: #5064ee !important;
    }

    .t-error {
        color: #e43638 !important;
    }

    .t-black {
        color: #000 !important;
    }

    .t-red {
        color: #f15634 !important;
    }

    .t-black-green {
        color: #166969 !important;
    }

    .t-op-04 {
        opacity: 0.4 !important;
    }

    .t-op-06 {
        opacity: 0.6 !important;
    }

    .t-op-07 {
        opacity: 0.7 !important;
    }

    .t-op-08 {
        opacity: 0.8 !important;
    }

    .t-gray {
        color: #9d9d9d !important;
    }

    .t-gray-medium {
        color: #5b5b5b !important;
    }

    .t-gray-light {
        color: #989898 !important;
    }

    .bg-gray-light {
        background-color: #f5f5f5;
    }

    .bg-white {
        background-color: #fff;
    }

    .border-gray-light-bottom-4 {
        border-bottom: 4px solid #f5f5f5;
    }
`;

export default Colors;

import qs from "qs";
import axios from "~/services/api";
import { ActingArea } from "~/types/actingAreas";
import { ViewResponse, Response } from "~/types/http";

export type ListActingAreasResponse = Response<ActingArea[]>;
export type ListActingAreasRequest = {
    filter?: { id_area_atuacao: number[] };
    doNotPaginate?: boolean;
    page?: number;
};

export async function listActingAreas({ filter, doNotPaginate, page }: ListActingAreasRequest): Promise<ListActingAreasResponse> {
    let query: Record<string, any> = {};
    if (filter) {
        query = { ...filter };
    }

    if (doNotPaginate) {
        query.doNotPaginate = 1;
    }

    if (page) {
        query.page = page;
    }

    const queryStr = qs.stringify(query);

    return axios.get(`/v1/acting-areas?${queryStr}`).then(res => res.data);
};

export type GetActingAreaResponse = ViewResponse<ActingArea>;
export type GetActingAreaRequest = {
    id: number;
};

export async function getActingArea({ id }: GetActingAreaRequest): Promise<GetActingAreaResponse> {
    return axios.get(`/v1/acting-areas/${id}`).then(res => res.data);
};

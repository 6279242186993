import qs from "qs";
import axios from "~/services/api";
import { BriefingConfig, TimeTable } from "~/types/scheduling";

export type GetSchedulingConfigResponse = BriefingConfig;
export type GetSchedulingConfigRequest = { organizationId: number; briefingId: number; token?: string };

export async function getSchedulingConfig({ organizationId, briefingId, token }: GetSchedulingConfigRequest): Promise<GetSchedulingConfigResponse> {
    const query = token ? qs.stringify({ token }) : "";

    const path = token
        ? `/v1/business/organizations/${organizationId}/briefings/${briefingId}/external-scheduling-configurations?${query}`
        : `/v1/business/organizations/${organizationId}/briefings/${briefingId}/scheduling-configurations`;

    return axios.get(path).then(res => res.data);
};

export type ListBriefingsResponse = any;

export async function getBriefings(): Promise<ListBriefingsResponse> {
    return axios.get(`/v1/business/briefings`).then(res => res.data);
}

export type GetTimeTableForDateResponse = TimeTable;
export type GetTimeTableForDateRequest = {
    organizationId: number;
    briefingId: number;
    date: string;
    cityName: string;
    uf: string;
    region?: string;
    size?: string;
    token?: string;
};

export async function getTimeTableForDate({
    organizationId,
    briefingId,
    date,
    cityName,
    uf,
    region,
    size,
    token = null
}: GetTimeTableForDateRequest): Promise<GetTimeTableForDateResponse> {
    const query = token ? qs.stringify({ date, t: token, cityName, region, size, uf }) : qs.stringify({ date, cityName, region, size, uf });

    const path = token
        ? `/v1/schedule/organizations/${organizationId}/briefings/${briefingId}/time-table?${query}`
        : `/v1/organizations/${organizationId}/briefings/${briefingId}/time-table?${query}`;

    return axios.get(path).then(res => res.data);
};

import styled from "styled-components";

const Container = styled.div`
    background-color: #fff;
    margin: 0 auto;
    max-width: 3000px;
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    position: sticky;
    top: 0;
    z-index: 49;

    .header-lg {
        display: flex;
        flex: auto;
        margin: 0;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        padding-top: 10px;
        padding-left: 15px;
        padding-right: 15px;
        max-width: 100%;
    }

    .header-lg div {
        flex-grow: 1;
    }

    .header-lg div:nth-child(2) {
        width: 60%;
    }

    .logo img {
        height: 18px;
    }

    .header{
        display: none
    }

    .menu .userCompany button {
        all: unset !important;
        color: #000000 !important;
        opacity: 0.6 !important;
        cursor: pointer !important;
    }


    .menu li a {
        color: #9d9d9d !important;
        text-decoration: none;
    }

    .menu li a:hover,
    .menu li a.current {
        border-bottom: 2px solid #fe8418;
        color: #000 !important;
        font-weight: 500;
    }

    .menu li a,
    .menu li span,
    .menu li button {
        padding: 1.5rem 1.4rem;
    }

    .menu-side-right {
        width: 100%;
    }

    .menu-side-right.menu li a,
    .menu-side-right.menu li span,
    .menu-side-right.menu li button {
        padding: 5px 5px;
        margin: 0px 10px;
    }

    .menu-bottom {
        background-color: #000;
    }

    .menu-mobile {
        margin: 0;
        margin-top: 5px;
    }

    .menu-mobile .icon-menu {
        margin-top: 4px;
        padding-left: 10px;
    }

    .menu {
        display: inline-block;
        flex: auto;
        align-items: center;
        justify-content: space-between;
        text-align: right;
    }

    .menu ul {
        align-items: center;
        display: inline-flex;
    }

    .menu li {
        display: inline-block;
    }

    .menu .menuMiddle {
        display: inline-block;
        width: 80%;
    }

    .menu .btnRedondo {
        background: #fafafa;
        border-radius: 100%;
        width: 45px;
        height: 45px;
        font-size: 12px;
    }

    .menu .userFullName {
        color: rgba(0, 0, 0, 0.6);
        font-weight: 500;
        font-size: 16px;
        margin-right: 0px !important;
    }

    .menu .userCompany {
        color: rgba(0, 0, 0, 0.4);
        font-weight: 500;
        font-size: 12px;
        margin-right: 0px !important;
    }

    @media(max-width:992px ){
        .menu li a,
        .menu li span,
        .menu li button {
            padding: 0.9rem 1.4rem;
        }
    }
`;

export const MobileMenu = styled.button`
    float: left;
    background: #dcdcdc;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
`;

export const Submenu = styled.div`
    position: absolute;
    top: 46px;
    display: none;
    z-index: 11;

    &.submenu ul {
        display: block !important;
        text-align: left;
        width: 250px;
    }

    &.submenu li {
        background: #fff;
        width: 100%;
    }

    &.submenu li a {
        padding: 15px;
        display: block;
        width: 100%;
    }
`;

export const MenuItem = styled.li`
    &:hover ${Submenu} {
        display: block;
    }
`;

export const MobileMenuItems = styled.div`
    display: block !important;
    ul {
        display: block !important;
    }

    ul li {
        display: block !important;
        width: 100%;
        text-align: left;
        margin: 20px 0;
    }

    ${Submenu} {
        position: relative;
        top: auto;
    }

    ${Submenu} ul {
        width: 100%;
    }

    ${Submenu} ul li {
        margin: 0 0.4rem;
    }
`;

export const ContextSubmenu = styled.div`
    background: ${({ theme }) => theme.color.primary.background};

    ul {
        text-align: center;
    }

    ul li {
        display: inline;
    }

    ul li a {
        color: ${({ theme }) => theme.color.primary.foreground};
        text-decoration: none;
        padding: 16px;
        display: inline-block;
        border-bottom: 2px solid transparent;
    }

    ul li a:hover,
    ul li.active a {
        border-bottom: 2px solid #fff;
        font-weight: bold;
    }
`;

export default Container;

import React, { useEffect } from "react";
import { FiAlertCircle, FiCheckCircle, FiInfo, FiXCircle } from "react-icons/fi";

import { Container } from "./styles";
import { ToastMessages, useToast } from "~/hooks/useToast";

interface ToastContentProps {
    message: ToastMessages;
}

const ToastContent: React.FC<ToastContentProps> = ({ message }) => {
    const { removeToast } = useToast();

    const icons = {
        info: <FiInfo size={24} />,
        error: <FiAlertCircle size={24} />,
        success: <FiCheckCircle size={24} />
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            removeToast(message.id);
        }, 3000);

        return () => {
            clearTimeout(timer);
        };
    }, [removeToast, message.id]);
    return (
        <Container key={message.id} type={message.type}>
            {icons[message.type || "info"]}
            <div>
                <strong>{message.title}</strong>
                {message.description && <p>{message.description}</p>}
            </div>
            <button type="button" onClick={() => removeToast(message.id)}>
                <FiXCircle size={18} />
            </button>
        </Container>
    );
};

export default ToastContent;

import ReactGA from "react-ga";

const GA_TRACKING_ID = "UA-150575920-1";

export const analyticsPageview = (url) => {
    ReactGA.initialize(GA_TRACKING_ID);
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
    //window.fbq("track", "PageView");
};

export const analyticsPurchaseEvent = (jobPrice) => {
    ReactGA.initialize(GA_TRACKING_ID);
    ReactGA.event({
        category: "EventoFJ",
        action: "Completo",
        label: "Pagamento",
        value: jobPrice,
    });
    window.fbq("track", "Purchase", { currency: "BRL", value: jobPrice });
};

export const analyticsScheduleEvent = () => {
    ReactGA.initialize(GA_TRACKING_ID);
    ReactGA.event({
        category: "EventoFJ",
        action: "Completo",
        label: "Booking",
    });
    if (window.fbq && typeof window.fbq === "function") {
        window.fbq("track", "Schedule");
    }
};

import styled from "styled-components";

export default styled.div`
    background-color: #f5f5f5;
    margin: 0 auto;
    max-width: 3000px;
    min-height: 100vh;

    button.btn-find-all {
        all: unset;
        cursor: pointer;
    }

    a {
        text-decoration: none;
    }

    i.btn-find-all {
        padding-top: 10px;
        padding-right: 13px;
        background-color: #fff;
        font-size: 16px;
        color: #000;
        width: 36px;
        height: 36px;
        border-radius: 20px;
    }

    .btn-order {
        border-radius: 30px;
        padding-left: 25px;
        width: 16rem;
        height: 3rem;
        color: #9d9d9d;
    }

    .btn-order i.btn-compl {
        padding-top: 9px;
        background-color: #000;
        font-size: 16px;
        color: #fff;
        width: 36px;
        height: 36px;
        border-radius: 20px;
    }

    i.jobs-indicator-arrow {
        color: #000;
        opacity: 0.2;
    }

    .job-indicator-align-center {
        justify-content: center !important;
    }

    .center-component {
        justify-content: center !important;
    }

    div.job-indicator {
        width: 136px;
        height: 130px;
        background-color: #fff;
        border-radius: 28px;
        cursor: pointer;

        margin: 0.5rem;

        .title{
           font-size: 1rem;
        }
    }

    div.next-jobs {
        width: 90%;
        background-color: #fff;
        border-radius: 8px;
        cursor: pointer;
    }

    div.btn-request-job {
        width: 95%;
    }

    .job-alert {
        background-color: #f15634 !important;
        color: #fbff21;
    }

    .presentation{
        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 1.6rem;

        a {
            margin-left: 3rem;

            button {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 0.9rem;

                padding: 0.5rem 1rem;
            }
        }
    }

    @media (max-width: 992px){

        .presentation {
            flex-direction: column;

            a {
                margin-left: 0px;
                margin-top: 1.5rem;
            }
        }

        div.job-indicator {
            .title{
                font-size: 0.9rem;
            }
        }
    }


    @media (min-width: 360px) {
        div.job-indicator {
            width: 170px;
            height: 164px;
            background-color: #fff;
            border-radius: 28px;

        }
    }

    @media (min-width: 820px) {
        div.job-indicator {
            width: 196px;
            height: 188px;
            background-color: #fff;
            margin-left: 30px;
            border-radius: 28px;
        }
    }

    @media (min-width: 920px) {
        div.next-jobs {
            width: 950px;
            padding-bottom: 15px;
            background-color: #fff;
            border-radius: 8px;
        }

        div.next-jobs-title {
            width: 950px;
            padding-left: 0px;
        }

        div.btn-request-job {
            width: 950px;
            height: 60px;
        }
    }
`;

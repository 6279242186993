import qs from "qs";
import axios from "~/services/api";
import { Response } from "~/types/http";
import { FieldDict } from "~/types/extraFields";
import { SchedulingConfig } from "~/types/scheduling";
import { JobIntegration, CreateJobIntegration } from "~/types/integrations";
import { OrganizationPhotographer, OrganizationRole } from "~/types/organization";
import { AnyRecord } from "~/types/record";

export type GetSchedulingConfigResponse = {
    regionIsRequired: boolean;
    extra?: {
        address?: FieldDict;
    };
} & SchedulingConfig;

export type GetSchedulingConfigRequest = {
    organizationId: number;
    segmentId: number;
    token?: string;
};

export async function getPreSchedulingConfig({
    organizationId,
    segmentId,
    token,
}: GetSchedulingConfigRequest): Promise<GetSchedulingConfigResponse> {
    const query = token ? qs.stringify({ segmentId, token }) : qs.stringify({ segmentId });

    const path = token
        ? `/v1/organizations/${organizationId}/external-scheduling-config?${query}`
        : `/v1/organizations/${organizationId}/scheduling-config?${query}`;

    return axios.get(path).then((res) => res.data);
}

export type ListOrganizationRolesResponse = Response<OrganizationRole[]>;
export type ListOrganizationRolesRequest = { organizationId: number };

export const listOrganizationRoles = async ({
    organizationId,
}: ListOrganizationRolesRequest): Promise<ListOrganizationRolesResponse> => {
    return axios.get(`/v1/organizations/${organizationId}/roles`).then((res) => res.data);
};

export type GetJobIntegrationsResponse = JobIntegration[];

export const getJobIntegrations = async (): Promise<GetJobIntegrationsResponse> => {
    return axios.get(`/v1/quality/organizations/me/integrations`).then(res => res.data);
};

export type CreateJobIntegrationsResponse = any;
export type CreateJobIntegrationsRequest = { data: CreateJobIntegration };

export const createJobIntegrations = async ({
    data
}: CreateJobIntegrationsRequest): Promise<CreateJobIntegrationsResponse> => {
    return axios.post(`/v1/quality/organizations/me/integrations`, data).then(res => res.data);
};

export type UpdateJobIntegrationsResponse = any;
export type UpdateJobIntegrationsRequest = { id: number; data: CreateJobIntegration };

export const updateJobIntegrations = async ({
    id,
    data
}: UpdateJobIntegrationsRequest): Promise<UpdateJobIntegrationsResponse> => {
    return axios.patch(`/v1/quality/organizations/me/integrations/${id}`, data).then(res => res.data);
};

export type UpdateUserPermissionResponse = any;
export type UpdateUserPermissionRequest = {
    organizationId: number;
    userId: number;
    roleId: number | null;
};

export const updateUserPermission = async ({
    organizationId,
    userId,
    roleId
}: UpdateUserPermissionRequest): Promise<UpdateUserPermissionResponse> => {
    const url = `/v1/organizations/${organizationId}/users/${userId}/roles`;
    return axios.patch(url, { roleId }).then((res) => res.data);
};

export type UpdateVideoProductLogoResponse = { videoProductLogo: string };
export type UpdateVideoProductLogoRequest = { organizationId: number; logo: string };

export const updateVideoProductLogo = async ({
    organizationId,
    logo
}: UpdateVideoProductLogoRequest): Promise<UpdateVideoProductLogoResponse> => {
    const url = `/v1/organizations/${organizationId}/video-slideshow-logo`;
    return axios.patch(url, { logo }).then((res) => res.data);
};

export type GetVideoProductLogoResponse = { videoProductLogo: string };
export type GetVideoProductLogoRequest = { organizationId: number };

export const getVideoProductLogo = async ({ organizationId }: GetVideoProductLogoRequest): Promise<GetVideoProductLogoResponse> => {
    const url = `/v1/organizations/${organizationId}/video-slideshow-logo`;
    return axios.get(url).then((res) => res.data);
};

export type UpdateWebhookConfigResponse = any;
export type UpdateWebhookConfigRequest = { exposeFilesForWebhook: "ativo" | "inativo" };
export const updateWebhookConfig = async (data: UpdateWebhookConfigRequest): Promise<UpdateWebhookConfigResponse> => {
    const url = `/v1/organizations/webhook-config`;
    return axios.patch(url, data).then((res) => res.data);
}
export type GetWebhookConfigResponse = { exposeFilesForWebhook: "ativo" | "inativo" };
export const getWebhookConfig = async (): Promise<GetWebhookConfigResponse> => {
    const url = `/v1/organizations/webhook-config`;
    return axios.get(url).then((res) => res.data);
}

export type GetApiConfigResponse = { hasToken: boolean };
export type GetApiConfigRequest = { showToken: boolean };

export const getApiConfig = async ({ showToken }: GetApiConfigRequest): Promise<GetApiConfigResponse> => {
    const query = showToken ? "showToken=1" : "";
    return axios.get(`/v1/organizations/api-config?${query}`).then((res) => res.data);
}

export type GenerateApiTokenResponse = { firstToken: boolean };

export const generateApiToken = async (): Promise<GenerateApiTokenResponse> => {
    return axios.patch(`/v1/organizations/api-config`, { generateToken: true }).then((res) => res.data);
}

export type GetPhotographersResponse = OrganizationPhotographer[];

export const getPhotographers = async (): Promise<GetPhotographersResponse> => {
    const url = `/v1/organizations/photographers`;
    return axios.get(url).then(res => res.data);
};

export type UpdateBillingInfoRequest = {
    companyName: string;
    cnpj: string;
    address: string;
    number: string;
    complement: string | null;
    neighborhood: string;
    cityId: string;
    stateId: string;
    zipCode: string;
    financialContactName: string;
    financialContactEmail: string;
};

export const updateBillingInfo = async (data: UpdateBillingInfoRequest) => {
    const url = `/v1/organizations/billing-info`;
    return axios.patch(url, data).then(res => res.data);
};

type GetOrganizationDataRequest = {
    id: number;
};

export const getOrganizationData = async ({ id }: GetOrganizationDataRequest): Promise<AnyRecord> => {
    return axios.get(`/v1/organizations/${id}`).then(res => res.data);
};

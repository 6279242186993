import axios from "~/services/api";

export type FetchStateResponse = any;
export type FetchStateRequest = { countryId: number };

export async function fetchState({ countryId }: FetchStateRequest): Promise<FetchStateResponse> {
    const params = {
        filter: {
            id_pais: countryId
        },
        project: ["id_estados", "uf"]
    };

    return axios.get(`/v1/states`, { params }).then(res => res.data);
}

export type FetchCityResponse = any;
export type FetchCityRequest = { stateId: number };

export async function fetchCity({ stateId }: FetchCityRequest): Promise<FetchCityResponse> {
    const params = { filter: { id_estados: stateId } };

    return axios.get(`/v1/cities`, { params }).then(res => res.data);
}

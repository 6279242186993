import qs from "qs";
import axios from "~/services/api";
import { User } from "~/types/users";
import { Response } from "~/types/http";

export type GetUsersResponse = Response<User[]>;
export type GetUsersRequest = { page: number; search: string };

export async function getUsers({ page, search }: GetUsersRequest): Promise<GetUsersResponse> {
    const query = qs.stringify({ page, search });

    return axios.get(`/v1/organization/users?${query}`).then(res => res.data);
};

export type ToggleUserActivationResponse = any;
export type ToggleUserActivationRequest = {
    userId: number;
    status: "ativo" | "inativo"
};

export async function toggleUserActivation({ userId, status }: ToggleUserActivationRequest): Promise<ToggleUserActivationResponse> {
    return axios.patch(`/v1/organization/users/${userId}/status`, { status }).then(res => res.data);
}

export type CreateUserResponse = any;
export type CreateUserRequest = {
    name: string;
    email: string;
    phone: string;
    password: string;
};

export async function createUser(data: CreateUserRequest): Promise<CreateUserResponse> {
    return axios.post(`/v1/organization/users`, data).then(res => res.data);
}

export type ChangeProfileResponse = { token: string };
export type ChangeProfileRequest = {
    profile: "marketplace" | "business"
};

export async function changeProfile(data: ChangeProfileRequest): Promise<ChangeProfileResponse> {
    return axios.patch(`/v1/users/change-profile`, data).then(res => res.data);
}

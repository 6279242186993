import { getActingArea, listActingAreas } from "./actingAreas";
import { getBriefings, getSchedulingConfig, getTimeTableForDate } from "./briefings";
import { getExtraFields } from "./extraFields";
import { createGallerySharing, removeGallerySharing, getGalleryByToken } from "./jobs/galleries";
import { postAddress, scheduleJobByToken } from "./jobs/scheduling";
import { sendContact } from "./lp";
import {
    getPreSchedulingConfig,
    getJobIntegrations,
    createJobIntegrations,
    updateJobIntegrations,
    listOrganizationRoles,
    updateUserPermission,
    updateVideoProductLogo,
    getVideoProductLogo,
    updateWebhookConfig,
    getWebhookConfig,
    getApiConfig,
    generateApiToken,
    getPhotographers,
    updateBillingInfo,
    getOrganizationData,
} from "./organizations";
import { listCreatomateTemplates } from "./creatomate";
import {
    createJob,
    updateCustomerCode,
    updateInPlaceContact,
    getJobInfo,
    getDownloadUrl,
    generateSlideshow,
    updateAddress,
    updateObservation,
} from "./jobs";
import { sendLog } from "./log";
import { changePassword } from "./profile/changePassword";
import { getUsers, toggleUserActivation, createUser, changeProfile } from "./users";
import { exchangeToken, acceptJob, refuseJob } from "./transmissionList";
import { fetchCity, fetchState } from "./location";
import {
    getJobStatusCategory,
    getNoshowReasons,
    getRefuseReasons,
    getJobSla,
    exportFollowUp,
    getIntegrationReport,
    getRequests,
    getFeedbacks,
    getDelays,
    getWeeklySchedule,
    getDailySchedule
} from "./reports";
import { searchEntity, executeIntegration } from "./integrations";

export default function createApi() {
    return {
        reports: {
            integrations: getIntegrationReport,
            jobs: {
                exportFollowUp,
                statusCategory: getJobStatusCategory,
                refuseReasons: getRefuseReasons,
                noshowReasons: getNoshowReasons,
                sla: getJobSla,
                requests: getRequests,
                feedbacks: getFeedbacks,
                delays: getDelays,
                weeklySchedule: getWeeklySchedule,
                dailySchedule: getDailySchedule
            },
        },
        creatomate: {
            templates: {
                list: listCreatomateTemplates,
            },
        },
        location: {
            state: fetchState,
            city: fetchCity,
        },
        transmissionList: {
            exchangeToken,
            acceptJob,
            refuseJob,
        },
        log: sendLog,
        profile: { changePassword },
        actingAreas: {
            list: listActingAreas,
            get: getActingArea,
        },
        extraFields: {
            get: getExtraFields,
        },
        organizations: {
            get: getOrganizationData,
            billingInfo: {
                update: updateBillingInfo,
            },
            briefings: {
                list: getBriefings,
                schedulingConfig: getSchedulingConfig,
                timeTableForDate: getTimeTableForDate,
            },
            schedulingConfig: {
                get: getPreSchedulingConfig,
            },
            users: {
                get: getUsers,
                create: createUser,
                changeStatus: toggleUserActivation,
                changeProfile: changeProfile,
                updateRole: updateUserPermission,
            },
            integrations: {
                list: getJobIntegrations,
                create: createJobIntegrations,
                update: updateJobIntegrations,
            },
            roles: {
                list: listOrganizationRoles,
            },
            videoProduct: {
                logo: {
                    get: getVideoProductLogo,
                    update: updateVideoProductLogo,
                },
            },
            api: {
                config: {
                    get: getApiConfig,
                    generateToken: generateApiToken,
                },
            },
            webhook: {
                config: {
                    update: updateWebhookConfig,
                    get: getWebhookConfig,
                },
            },
            photographers: {
                list: getPhotographers,
            },
        },
        jobs: {
            create: createJob,
            info: getJobInfo,
            galleries: {
                generateSlideshow,
                share: createGallerySharing,
                unshare: removeGallerySharing,
                getByToken: getGalleryByToken,
                downloadUrl: getDownloadUrl,
            },
            scheduling: {
                afterFillAddress: postAddress,
                scheduleByToken: scheduleJobByToken,
            },
            customerCode: {
                update: updateCustomerCode,
            },
            inPlaceContact: {
                update: updateInPlaceContact,
            },
            integration: { searchEntity, executeIntegration },
            address: { update: updateAddress },
            observation: { update: updateObservation },
        },
        lp: {
            sendContact,
        },
    };
}

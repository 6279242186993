import { useRef } from "react";
import useOutsideAlerter from "~/hooks/useOutsideAlerter";

export default function OutsideAlerter({ onOutside, children, ...props }) {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, onOutside);

    return (
        <div {...props} ref={wrapperRef}>
            {children}
        </div>
    );
}

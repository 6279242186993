import qs from "qs";
import axios from "~/services/api";
import { formatDate } from "~/utils/date";
import {
    JobStatusByCategoryStats,
    RefuseResaonsStats,
    NoshowReasonsStats,
    JobSlaStats,
    IntegrationStats,
    RequestsStats,
    DefaultStats
} from "~/types/reports";

export type StatsRequest = { start: Date; end: Date; period: string, filtredCurentWeekly?:boolean };

const createQueryFilters = ({ start, end, period, filtredCurentWeekly }: StatsRequest): string => {
    const startDate = `${formatDate(start, "yyyy-MM-dd")} 00:00:00`;
    const endDate = `${formatDate(end, "yyyy-MM-dd")} 23:59:59`;

    return qs.stringify({ start: startDate, end: endDate, period, filtredCurentWeekly });
};

export type GetJobStatusCategoryResponse = JobStatusByCategoryStats;

export async function getJobStatusCategory({
    start,
    end,
    period,
    filtredCurentWeekly
}: StatsRequest): Promise<GetJobStatusCategoryResponse> {
    const query = createQueryFilters({ start, end, period, filtredCurentWeekly });

    return axios.get(`/v1/reports/jobs/status-category?${query}`).then((res) => res.data);
}

export type GetRefuseReasonsResponse = RefuseResaonsStats;

export async function getRefuseReasons({ start, end, period, filtredCurentWeekly }: StatsRequest): Promise<GetRefuseReasonsResponse> {
    const query = createQueryFilters({ start, end, period, filtredCurentWeekly });

    return axios.get(`/v1/reports/jobs/refused-schedule?${query}`).then((res) => res.data);
}

export type GetNoshowReasonsResponse = NoshowReasonsStats;

export async function getNoshowReasons({ start, end, period, filtredCurentWeekly }: StatsRequest): Promise<GetNoshowReasonsResponse> {
    const query = createQueryFilters({ start, end, period, filtredCurentWeekly });

    return axios.get(`/v1/reports/jobs/noshow-motives?${query}`).then((res) => res.data);
}

export type GetJobSlaResponse = JobSlaStats;

export async function getJobSla({ start, end, period, filtredCurentWeekly }: StatsRequest): Promise<GetJobSlaResponse> {
    const query = createQueryFilters({ start, end, period, filtredCurentWeekly });

    return axios.get(`/v1/reports/jobs/sla?${query}`).then((res) => res.data);
}

export async function getRequests({ start, end, period, filtredCurentWeekly }: StatsRequest): Promise<DefaultStats> {
    const query = createQueryFilters({ start, end, period, filtredCurentWeekly });

    return axios.get(`/v1/reports/jobs/requests?${query}`).then((res) => res.data);
}

export async function getFeedbacks({ start, end, period, filtredCurentWeekly }: StatsRequest): Promise<DefaultStats> {
    const query = createQueryFilters({ start, end, period, filtredCurentWeekly });

    return axios.get(`/v1/reports/jobs/feedbacks?${query}`).then((res) => res.data);
}

export async function getDelays({ start, end, period, filtredCurentWeekly }: StatsRequest): Promise<DefaultStats> {
    const query = createQueryFilters({ start, end, period, filtredCurentWeekly });

    return axios.get(`/v1/reports/jobs/delays?${query}`).then((res) => res.data);
}

export async function getWeeklySchedule({ start, end, period, filtredCurentWeekly }: StatsRequest): Promise<DefaultStats> {
    const query = createQueryFilters({ start, end, period, filtredCurentWeekly });

    return axios.get(`/v1/reports/jobs/schedule-weekly?${query}`).then((res) => res.data);
}

export async function getDailySchedule({ start, end, period, filtredCurentWeekly }: StatsRequest): Promise<DefaultStats> {
    const query = createQueryFilters({ start, end, period, filtredCurentWeekly });

    return axios.get(`/v1/reports/jobs/schedule-daily?${query}`).then((res) => res.data);
}

export async function exportFollowUp({ start, end, period, filtredCurentWeekly }: StatsRequest): Promise<any> {
    const query = createQueryFilters({ start, end, period, filtredCurentWeekly });

    return axios.get(`/v1/reports/jobs/export-followup?${query}`).then((res) => res.data);
}

export async function getIntegrationReport({ start, end, period, filtredCurentWeekly }: StatsRequest): Promise<IntegrationStats> {
    const query = createQueryFilters({ start, end, period, filtredCurentWeekly });

    return axios.get(`/v1/reports/integrations?${query}`).then((res) => res.data);
}

export default {
    fonts: {
        title: {
            family: "Inter, sans-serif",
            sizes: [28, 24, 22, 20, 18, 16],
            weights: [600, 600, 600, 600, 600, 600]
        }
    },
    color: {
        base: "#5c5c5c",
        baseLighter: "#b7b7b7",
        primary: {
            background: "#FE8418",
            foreground: "#FFFFFF",
            accent: "#D36300",
            variations: {
                lighter: {
                    background: "#FFCB9D",
                    foreground: "#FFFFFF",
                    accent: "#D36300"
                },
                darker: {
                    background: "#D36300",
                    foreground: "#FFFFFF",
                    accent: "#2E2E2E"
                }
            }
        },
        secondary: {
            background: "#898989",
            foreground: "#FFFFFF",
            accent: "#5C5C5C",
            variations: {
                lighter: {
                    background: "#898989",
                    foreground: "#FFFFFF",
                    accent: "#5C5C5C"
                },
                darker: {
                    background: "#898989",
                    foreground: "#FFFFFF",
                    accent: "#5C5C5C"
                }
            }
        },
        terciary: {
            background: "#fff",
            foreground: "#2E2E2E",
            accent: "#2E2E2E",
            variations: {
                lighter: {
                    background: "#fff",
                    foreground: "#2e2e2e",
                    accent: "#2e2e2e"
                },
                darker: {
                    background: "#fff",
                    foreground: "#2e2e2e",
                    accent: "#2e2e2e"
                }
            }
        },
        danger: {
            background: "#F24A50",
            foreground: "#FFFFFF",
            accent: "#B91319",
            variations: {
                lighter: {
                    background: "#ED907B",
                    foreground: "#FFFFFF",
                    accent: "#F24A50"
                },
                darker: {
                    background: "#B91319",
                    foreground: "#FFFFFF",
                    accent: "#B91319"
                }
            }
        },
        success: {
            background: "#00B123",
            foreground: "#FFFFFF",
            accent: "#5C5C5C",
            variations: {
                lighter: {
                    background: "#7ae88f",
                    foreground: "#FFFFFF",
                    accent: "#00B123"
                },
                darker: {
                    background: "#10EC3B",
                    foreground: "#FFFFFF",
                    accent: "#00B123"
                }
            }
        },
        warning: {
            background: "#fff68c",
            foreground: "#604d08",
            accent: "#604d08",
            variations: {
                lighter: {
                    background: "#7ae88f",
                    foreground: "#FFFFFF",
                    accent: "#00B123"
                },
                darker: {
                    background: "#10EC3B",
                    foreground: "#FFFFFF",
                    accent: "#00B123"
                }
            }
        }
    },
    colors: {
        white: ["#ffff"],
        orange: ["#FECD7A", "#FDBB4D", "#FDB778", "#ff7e00", "#BD7738"],
        green: ["#EEFFF1", "#27C245", "#1D8E33"],
        blue: ["#64B9CD", "#3FB2CC", "#095366"],
        red: ["#FFD1D1", "#FD7148", "#D93716", "#A42B0F"],
        grey: ["#F5F5F5", "#E9E9E9", "#B7B7B7", "#6C6C6C", "#505050"],
        alert: {
            primary: {
                border: "#97BFD5",
                background: "#BCDFF1"
            },
            secondary: {
                border: "#D4D7DB",
                background: "#E9E9E9"
            },
            success: {
                border: "#D0E9C6",
                background: "#EEFFF1"
            },
            danger: {
                border: "#EBCCCC",
                background: "#FFD1D1"
            },
            warning: {
                border: "#FAF2CC",
                background: "#FFF5C9"
            }
        }
    },
    fontSizes: ["16px", "20px", "24px", "28px", "32px", "40px"]
};

import axios from "~/services/api";

export type CreateGallerySharingResponse = { token: string };
export type CreateGallerySharingRequest = {
    jobId: number;
};

export async function createGallerySharing({
    jobId
}: CreateGallerySharingRequest): Promise<CreateGallerySharingResponse> {
    return axios.patch(`/v1/jobs/${jobId}/galleries/share`).then(res => res.data);
}

export type RemoveGallerySharingRequest = { jobId: number };

export async function removeGallerySharing({ jobId }: RemoveGallerySharingRequest): Promise<any> {
    return axios.delete(`/v1/jobs/${jobId}/galleries/unshare`).then(res => res.data);
}

export type GetGalleryByTokenRequest = { token: string };
export type GetGalleryByTokenResponse = {};

export async function getGalleryByToken({ token }: GetGalleryByTokenRequest): Promise<GetGalleryByTokenResponse> {
    return axios.get(`/v1/customer/jobs/files/${token}`).then(res => res.data);
}

import styled from "styled-components";

const Container = styled.div`
    height: 100%;
`;

const Form = styled.div`
    height: 100%;

    .btn-inter {
        font-family: "Inter", sans-serif;
        cursor: pointer;
    }

    .btn-purple {
        border: 1px #5064ee solid;
        background-color: #5064ee;
    }

    .btn-orange {
        border: 1px #fe8418 solid;
        background-color: #fe8418;
    }

    .btn-black {
        color: #fff;
        background-color: #000;
    }

    .btn-purple.btn-outlined {
        color: #5064ee;
    }

    .btn-purple:disabled::not(.btn-outlined) {
        background-color: #6672c7;
        border-color: #6672c7;
    }

    .btn-white {
        border: 1px #ffffff solid;
        background-color: white;
    }

    .btn-green {
        border: 1px #21b970 solid;
        background-color: #21b970;
    }

    .btn-full {
        width: 100%;
    }

    .btn-outlined {
        color: #1b1b1d;
        background-color: transparent;
    }

    .btn-outlined-white {
        border: 1px solid #fff;
        color: #fff;
        background-color: transparent;
    }

    .btn.arrow::after {
        content: "";
        position: absolute;
        background-image: url("../icons/icones_fotografoja-12.svg");
        background-size: 15px 15px;
        background-repeat: no-repeat;
        background-position: center center;
        width: 15px;
        height: 15px;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
    }

    .react-calendar {
        font-family: Inter;
        border: 1px solid #fff;
        margin: 0 auto;
    }

    abbr[title] {
        text-decoration: none;
    }

    .react-calendar__month-view__days__day--weekend {
        color: #000000;
    }

    .react-calendar__tile:disabled {
        background-color: #fff;
        color: #000000;
        opacity: 0.3;
    }

    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus {
        background-color: #fff;
    }
    .react-calendar__navigation button[disabled] {
        background-color: #fff;
    }

    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
        background-color: #5064ee;
    }

    .react-calendar__month-view__days {
        font-size: 10px;
    }

    .react-calendar__tile--active {
        background-color: #5064ee;
        color: #fff !important;
        border-radius: 30px;
    }

    .react-calendar button {
        font-size: 12px;
        flex-basis: 10%;
    }

    .react-calendar__navigation button {
        min-width: 0;
    }

    button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
        font-size: 15px;
    }

    button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
        font-size: 15px;
    }

    button.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
        font-size: 15px;
    }

    button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
        font-size: 15px;
    }

    .react-calendar__navigation__label span {
        font-size: 10px;
        font-weight: bold;
        line-height: 15px;
        letter-spacing: 0.4em;
        text-transform: uppercase;
    }

    .react-calendar,
    .react-calendar *,
    .react-calendar *:before,
    .react-calendar *:after {
        font-family: Inter;
        text-transform: lowercase;
        font-size: 10px;
        font-weight: 600;
        box-sizing: border-box;
        border-radius: 25px;
    }

    @media (min-width: 360px) {
        .react-calendar button {
            font-size: 13px;
            flex-basis: 10%;
        }

        .react-calendar__navigation__label span {
            font-size: 11px;
            font-weight: bold;
            line-height: 15px;
            letter-spacing: 0.4em;
            text-transform: uppercase;
        }
    }

    @media (min-width: 375px) {
        .react-calendar button {
            font-size: 15px;
        }

        .react-calendar__navigation__label span {
            font-size: 12px;
            font-weight: bold;
            line-height: 15px;
            letter-spacing: 0.4em;
            text-transform: uppercase;
        }
    }

    @media (min-width: 820px) {
        .react-calendar,
        .react-calendar *,
        .react-calendar *:before,
        .react-calendar *:after {
            font-family: Inter;
            text-transform: lowercase;
            font-size: 13px;
            font-weight: 100;
            box-sizing: border-box;
            border-radius: 30px;
        }

        .react-calendar__viewContainer {
            padding-left: 5%;
        }

        .react-calendar__navigation__label span {
            font-size: 13px;
            font-weight: bold;
            line-height: 15px;
            letter-spacing: 0.4em;
            text-transform: uppercase;
        }

        .react-calendar__month-view__weekdays__weekday {
            flex-basis: 12% !important;
            margin-left: 1.3% !important;
            max-width: 12% !important;
        }

        .react-calendar__tile {
            flex-basis: 12% !important;
            margin-left: 1.3% !important;
            max-width: 12% !important;
        }

        .react-calendar__tile--active {
            background-color: #5064ee;
            color: #fff !important;
            border-radius: 30px;
            flex-basis: 12% !important;
            margin-left: 1.3% !important;
            max-width: 12% !important;
        }
    }

    select {
        width: 100%;
        border-radius: 25px;
        background-color: #fff;
        height: 50px;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url("../icons/icones_fotografoja-10.svg");
        background-repeat: no-repeat;
        background-size: 10px 10px;
        background-position: top 20px right 30px;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
        padding: 0px 20px;
    }

    .check-box {
        width: 5% !important;
        height: 12px !important;
    }
`;

export default Form;

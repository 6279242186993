import { addDays, parse, format, isAfter, isEqual, isPast as isPastInner } from "date-fns";

type FormatDateAndTimeFnOptions = {
    dateFormat?: string;
    timeFormat?: string;
};

type FormatDateAndTimeFn = {
    (dateLike: string | number | Date, options?: FormatDateAndTimeFnOptions): string;
};


export const formatDateAndTime: FormatDateAndTimeFn = (dateLike, { dateFormat, timeFormat } = {}) => {
    const date = new Date(dateLike);

    const activeDateFormat = dateFormat || "dd/MM/yyyy";
    const activeTimeFormat = timeFormat || "HH:mm";

    return format(date, `${activeDateFormat} ${activeTimeFormat}`);
};

export const isAfterLimitOfDays = (date: Date, qtyDays: number = 1) => {
    date = new Date(date.setHours(0, 0, 0, 0));
    const limit = new Date(addDays(new Date(), qtyDays).setHours(0, 0, 0, 0));

    return isAfter(date, limit) || isEqual(date, limit);
};

export const isPast = (date: Date) => {
    return isPastInner(date);
};

export const formatBrazil = (date: Date) => {
    return format(date, "dd/MM/yyyy");
};

export const formatDate = (date: Date, pattern = "yyyy-MM-dd") => {
    return format(date, pattern);
};

export const formatTime = (date: Date, pattern = "HH:mm:ss") => {
    return format(date, pattern);
};

export const parseDate = (date: string, pattern = "yyyy-MM-dd") => {
    return parse(date, pattern, new Date());
};

export const sumDays = (date: Date, days: number) => {
    return addDays(date, days);
};

export const getDaysInMonth = (month: number, year: number) => {
    month--;

    const date = new Date(year, month, 1);
    const days = [];

    while (date.getMonth() === month) {
        days.push(date.getDate());
        date.setDate(date.getDate() + 1);
    }

    return days;
};

export const formatHeaderDate = (item: string, period: string) => {
    if (period === "weekly") {
        const [start, end] = item.split(" à ");
        return `${start.slice(0, 5)} à ${end.slice(0, 5)}`;
    }

    return item;
};

export const formatDateToDateIntervalInput: FormatDateAndTimeFn = (dateLike, { dateFormat } = {}) => {
    let dateToConvert = dateLike;
    if (typeof dateLike === "string" && dateLike.length == 10) {
        dateToConvert = `${dateLike} 16:00:00`;
    }

    const date = new Date(dateToConvert);

    const activeDateFormat = dateFormat || "dd/MM/yyyy";

    return format(date, activeDateFormat);
};

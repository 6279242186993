import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar:hover {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 100px;
}

  @font-face {
    font-family: 'Oswald';
    src: url('/fonts/Oswald-Medium.ttf');
  }

  @font-face {
    font-family: 'Oswald-Regular';
    src: url('/fonts/Oswald-Regular.ttf');
  }

  @font-face {
    font-family: 'Oswald-Light';
    src: url('/fonts/Oswald-Light.ttf');
  }

  @font-face {
    font-family: 'Inter';
    src: url('/fonts/Inter-Regular.ttf');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Inter';
    src: url('/fonts/Inter-Medium.ttf');
    font-weight: 500;
  }

  @font-face {
    font-family: 'Inter-Medium';
    src: url('/fonts/Inter-Medium.ttf');
    font-weight: 500;
  }

  @font-face {
    font-family: 'Inter';
    src: url('/fonts/Inter-SemiBold.ttf');
    font-weight: 600;
  }

  @font-face {
    font-family: 'Inter';
    src: url('/fonts/Inter-Bold.ttf');
    font-weight: bold;
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    box-sizing: border-box;
  }

  html, body, #__next{
    width: 100%;
    height: 99.4%;
  }

  .main {
    width: 100%;
  }

  .f-inter-regular {
    font-family: 'Inter';
  }

  .f-inter-medium {
    font-family: 'Inter-Medium';
    font-weight: 500;
  }

  .f-inter-semi-bold {
    font-family: 'Inter-Medium';
    font-weight: 600;
  }

  body{
    font-family: 'Inter', sans-serif;
  }

  .spin {
    -webkit-animation:spin 1s linear infinite;
    -moz-animation:spin 1s linear infinite;
    animation:spin 1s linear infinite;
  }

  @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
  @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
  @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

  .main{
    padding-top: 60px;
  }

  h1{
    font-size: 28px;
  }

  h2{
    font-size: 24px;
  }

  h3{
    font-size: 14px;
  }

  ul{
    list-style: none;
  }

  .head-logged {
    background-color: #000;
    z-index: 4;
    position: absolute;
    width: 100%;
    padding: 5px 20px;
    height: 60px;
  }

  .head-logged .container {
      display: flex;
      justify-content: space-between;
  }

  .head-logged .container .btn-return svg {
    fill: white;
    transform: rotate(180deg);
    width: 15px;
    height: 15px;
    margin-top: 20px;
  }

  .head-logged .container button.btn-return {
    background: none;
  }

  @media (max-width: 1200px) {
        .main{
          padding-top: 50px;
        }
    }

    .infinite-scroll-component__outerdiv {
        width: 100%;
    }

    .slick-arrow {
        width: 2rem;
        height: 2rem;

        :before {
            content: none;
        }
    }

    .slick-next {
        padding-left: 12px;
        padding-top: 8px;
    }

    .slick-prev {
        padding-left: 12px;
        padding-top: 8px;
    }

    .css-tzr0f2-Circle.active.using-icon {
        transform: scale(2);
        background: #FE8418;
    }

    .css-1upcvjq-VerticalCircleWrapper.left {
        width: 4rem;
        img {
            width: 4rem;
            height:4rem;
        }

        &:after {
        background: #FE8418;
        content: '';
        display: block;
        height: 100%;
        left: 0;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        right: 0;
        width: 10px;
        z-index: 0;
        }
    }
    .css-1upcvjq-VerticalCircleWrapper.right {

        &:after {
        background: #FE8418;
        content: '';
        display: block;
        height: 100%;
        left: 0;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        right: 0;
        width: 10px;
        z-index: 0;
        }
    }

    p {
        line-height: 1.25rem;
        color: #2e2e2e;
    }


`;

import * as Sentry from "@sentry/node";
import Head from "next/head";
import { ToastContainer } from "react-toastify";
import Router from "next/router";
import { AuthProvider } from "../hooks/useAuth";
import { LgpdNotifierProvider } from "../hooks/LgpdNotifier";
import "../lib/fontawesome-pro/scss/fontawesome.scss";
import "../lib/fontawesome-pro/scss/light.scss";
import "../lib/fontawesome-pro/scss/brands.scss";
import "../lib/fontawesome-pro/scss/solid.scss";
import "../lib/fontawesome-pro/scss/regular.scss";
import "../lib/fontawesome-pro/scss/duotone.scss";
import GlobalStyle from "../styles/global";
import Form from "../styles/form";
import Fonts from "../styles/fonts";
import Colors from "../styles/colors";
import "react-calendar/dist/Calendar.css";
import "../styles/base.css";

import { analyticsPageview } from "../services/analytics";

import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "styled-components";
import theme from "../styles/theme";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import "bootstrap/dist/css/bootstrap-grid.min.css";
// import "bootstrap/dist/css/bootstrap-utilities.min.css";

import "../styles/bootstrap-tab.scss";

import { ToastProvider } from "../hooks/useToast";

Router.events.on("routeChangeComplete", (url) => analyticsPageview(url));

// const GOOGLE_MAPS_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY;
const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const ENV = process.env.NODE_ENV;

if (SENTRY_DSN) {
    Sentry.init({
        enabled: ENV === "production" && SENTRY_DSN,
        dsn: SENTRY_DSN,
    });
}

const publicPaths = ["/imobiliario/tour-virtual-matterport"];

function MyApp({ Component, pageProps, err }) {
    return (
        <AuthProvider publicPaths={publicPaths}>
            <Head>
                <meta
                    name="viewport"
                    content="initial-scale=1.0,maximum-scale=1.0,user-scalable=no,width=device-width"
                />
                <meta
                    name="description"
                    content="Nem sempre é fácil conseguir fotos profissionais. Nós te conectamos aos melhores fotógrafos do Brasil."
                />

                <link rel="preconnect" href="https://connect.facebook.net" />
                <link rel="preconnect" href="https://www.facebook.com" />
                <link rel="preconnect" href="https://www.google-analytics.com" />
                <link rel="preconnect" href="https://www.googletagmanager.com" />

                <link rel="icon" href="/images/favi.png" />
                <meta property="og:image" content="/images/thumb-fotografoja.png" />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:image:width" content="510" />
                <meta property="og:image:height" content="510" />
                <title>Fotop Serviços</title>
            </Head>
            <ThemeProvider theme={theme}>
                <ToastProvider>
                    <LgpdNotifierProvider>
                        <ToastContainer />
                        <GlobalStyle />
                        <Form>
                            <Fonts>
                                <Colors>
                                    <Component {...pageProps} err={err} />
                                </Colors>
                            </Fonts>
                        </Form>
                    </LgpdNotifierProvider>
                </ToastProvider>
            </ThemeProvider>
        </AuthProvider>
    );
}

export default MyApp;

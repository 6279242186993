import axios from "~/services/api";

export type ExchangeTokenResponse = { job: any; partner: any };
export type ExchangeTokenRequest = { token: string };

export async function exchangeToken({ token }: ExchangeTokenRequest): Promise<ExchangeTokenResponse> {
    return axios.post(`/v1/transmission-lists/job-candidate`, { token }).then((res) => res.data);
}

export type AcceptJobResponse = { job: any; partner: any };
export type AcceptJobRequest = { token: string };

export async function acceptJob({ token }: AcceptJobRequest): Promise<AcceptJobResponse> {
    return axios
        .post(`/v1/transmission-lists/job-candidate/response`, { token, response: "accept" })
        .then((res) => res.data);
}

export type RefuseJobResponse = { job: any; partner: any };
export type RefuseJobRequest = { token: string };

export async function refuseJob({ token }: RefuseJobRequest): Promise<AcceptJobResponse> {
    return axios
        .post(`/v1/transmission-lists/job-candidate/response`, { token, response: "refuse" })
        .then((res) => res.data);
}

import qs from "qs";
import axios from "~/services/api";
import { CreatomateTemplate } from "~/types/creatomate";
import { Response } from "~/types/http";

export type ListCreatomateTemplatesResponse = Response<CreatomateTemplate[]>;

export async function listCreatomateTemplates(): Promise<ListCreatomateTemplatesResponse> {
    const queryStr = qs.stringify({ status: "ativo" });
    return axios.get(`/v1/quality/creatomate-user-templates?${queryStr}`).then(res => res.data);
};

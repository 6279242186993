import React from "react";

import { Container } from "./styles";
import ToastContent from "./ToastContent";
import { ToastMessages } from "~/hooks/useToast";
import { useTransition, animated } from "@react-spring/web";

interface ToastProps {
    messages: ToastMessages[];
}

const Toast: React.FC<ToastProps> = ({ messages }) => {
    const messagesWithTransition = useTransition(messages, {
        from: { right: "-30%", opacity: 0 },
        enter: { right: "0%", opacity: 1 },
        leave: { right: "-120%", opacity: 0 }
    });
    return (
        <Container hidden={!messages.length}>
            {messagesWithTransition((style, item) => (
                <animated.div style={style}>
                    <ToastContent message={item} />
                </animated.div>
            ))}
        </Container>
    );
};

export default Toast;

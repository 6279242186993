import axios from "~/services/api";

export type SendContactResponse = any;
export type SendContactRequest = {
    product: string;
    name: string;
    email: string;
    phone: string;
    message?: string;
};

export async function sendContact({
    product,
    name,
    email,
    phone,
    message
}: SendContactRequest): Promise<SendContactResponse> {
    const data = { name, email, phone, message };
    return axios.post(`/v1/contact/lp/${product}`, data).then(res => res.data);
}

import styled from "styled-components";

const Fonts = styled.div`
    height: 100%;

    .f-spacing-12 {
        line-height: 12px;
    }

    .f-spacing-25 {
        line-height: 25px;
    }

    .f-spacing-30 {
        line-height: 30px;
    }

    .f-spacing-35 {
        line-height: 35px;
    }

    .f-10 {
        font-size: 10px;
    }

    .f-12 {
        font-size: 12px;
    }

    .f-14 {
        font-size: 14px !important;
    }

    .f-16 {
        font-size: 16px;
    }

    .f-18 {
        font-size: 18px;
    }

    .f-20 {
        font-size: 20px;
    }

    .f-22 {
        font-size: 22px;
    }

    .f-26 {
        font-size: 26px;
    }

    .f-36 {
        font-size: 36px;
    }

    .f-46 {
        font-size: 46px;
    }

    .f-small-1 {
        font-size: 0.9vw;
    }

    .f-medium-1 {
        font-size: 1.1vw;
    }

    .f-medium-2 {
        font-size: 1.2vw;
    }

    .f-medium-3 {
        font-size: 1.3vw;
    }

    @media (min-width: 820px) {
        .f-lg-14 {
            font-size: 14px;
        }
    }

    .f-b {
        font-weight: bold;
    }

    .text-center {
        text-align: center !important;
    }

    .text-left {
        text-align: left !important;
    }

    .text-right {
        text-align: right !important;
    }
`;

export default Fonts;

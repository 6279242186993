import qs from "qs";
import axios from "~/services/api";
import { ViewResponse } from "~/adapters/api/types";
import { FieldDict, ContextData } from "~/types/extraFields";

export type GetFieldsResponse = ViewResponse<FieldDict>;
export type GetFieldsRequest = {
    contextName: string;
    contextData: ContextData
};

export async function getExtraFields({ contextName, contextData }: GetFieldsRequest): Promise<GetFieldsResponse> {
    const query = qs.stringify({ contextData });

    return axios.get(`/v1/extra-fields/${contextName}/fields?${query}`).then(res => res.data);
};

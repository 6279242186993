import axios from "~/services/api";

export type ChangePasswordResponse = any;
export type ChangePasswordRequest = {
    currentPassword: string;
    newPassword: string
};

export async function changePassword({ currentPassword, newPassword }: ChangePasswordRequest): Promise<ChangePasswordResponse> {
    return axios.put(`/v1/customer/password`, { currentPassword, newPassword }).then(res => res.data);
};

import axios from "~/services/api";

export type SearchEntityResponse = any;
export type SearchEntityRequest = { integrationId: number; reference: string };

export const searchEntity = async ({
    integrationId, reference
}: SearchEntityRequest): Promise<SearchEntityResponse> => {
    return axios.post(`/v1/quality/organizations/me/search-entity`, { integrationId, reference }).then(res => res.data);
};

export type ExecuteIntegrationResponse = any;
export type ExecuteIntegrationRequest = {
    integrationId: number;
    reference: string;
    jobId: number;
    files: { fileId: number; featured?: boolean };
};

export const executeIntegration = async ({ jobId, integrationId, reference, files }) => {
    return axios.post(`/v1/quality/organizations/me/execute-integration`, { jobId, integrationId, reference, files }).then(res => res.data);
};

import axios from "axios";

export const apiProvisoria = axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_PROVISORIA_URL_BASE,
});

const api = axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_URL_BASE,
});

export default api;

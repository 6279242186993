import { IconContext } from "react-icons";
import { FaSpinner } from "react-icons/fa";

type SizeOptions = "small" | "medium" | "large";

const getSize = (sizeStr: SizeOptions) => {
    if (sizeStr === "small") {
        return "1rem";
    }

    if (sizeStr === "large") {
        return "3rem";
    }

    return "2rem";
};

type SpinnerProps = {
    size?: SizeOptions;
};

const Spinner = ({ size = "small" }: SpinnerProps) => {
    return (
        <IconContext.Provider value={{ size: getSize(size), className: "spin" }}>
            <FaSpinner />
        </IconContext.Provider>
    );
};

export default Spinner;

import styled from "styled-components";

export const Container = styled.div<{ hidden?: boolean }>`
    position: fixed;
    right: 0;
    top: 0;
    padding: 30px;
    overflow-x: hidden;
    z-index: 9100;
    ${({ hidden }) => hidden ? "display: none;" : "display: block;"}
`;

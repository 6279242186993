import React, { useState } from "react";
import Link from "next/link";
import { RxHamburgerMenu } from "react-icons/rx";
import { useRouter } from "next/router";
import useAuth from "../../hooks/useAuth";
import ProfileMenu from "./ProfileMenu";
import Container, { MenuItem, MobileMenuItems, Submenu, MobileMenu, ContextSubmenu } from "./styles";
import { AnyRecord } from "~/types/record";

type DashboardHeaderProps = {
    hideMenu?: boolean;
    hideUser?: boolean;
    children?: any;
};

type MenuLink = {
    text: string;
    href: string;
    submenus?: { text: string; href: string }[];
};

type SubmenuItemProps = {
    children: any;
    href?: string;
    onClick?: () => void;
    active?: boolean;
};

export const SubmenuItem = ({ children, href, onClick, active = false }: SubmenuItemProps) => {
    return (
        <li className={`${active ? "active" : ""}`}>
            {href && <Link href={href}>{children}</Link>}
            {onClick && (
                <a onClick={onClick} style={{ cursor: "pointer" }}>
                    {children}
                </a>
            )}
        </li>
    );
};

const getMenus = (user: AnyRecord, account: any) => {
    const arrSortedMenuLinks: MenuLink[] = [
        {
            text: "Dashboard",
            href: user?.id_organizacoes ? "/dashboard" : "/marketplace/dashboard",
        }
    ];

    if (!user?.id_organizacoes) {
        arrSortedMenuLinks.push({ text: "Agendamento", href: "/marketplace/book" })
    } else if (user?.canBook) {
        arrSortedMenuLinks.push({
            text: "Agendamento",
            href: user?.id_organizacoes ? "/business/booking" : "/marketplace/book",
        });
    }

    arrSortedMenuLinks.push({
        text: "Jobs",
        href: "#",
        submenus: [{ text: "Lista de jobs", href: "/jobs/list" }],
    });

    if (user?.id_organizacoes && account.grantGalleryAccess) {
        arrSortedMenuLinks
            .find((item) => item.text === "Jobs")
            .submenus.push({ text: "Solicitações", href: "/requests" });
    }

    if (user?.id_organizacoes && account.grantGalleryAccess) {
        arrSortedMenuLinks
            .find((item) => item.text === "Jobs")
            .submenus.push({ text: "Acompanhamento", href: "/execution" });
    }

    if (user?.id_organizacoes && account.grantGalleryAccess) {
        arrSortedMenuLinks
            .find((item) => item.text === "Jobs")
            .submenus.push({ text: "Download arquivos", href: "/jobs/download-zip" });
    }

    if (user?.photographerManagementType === "proprio") {
        arrSortedMenuLinks
            .find((item) => item.text === "Jobs")
            .submenus.push({ text: "Atribuição do fotógrafo", href: "/jobs-dx" });
    }

    arrSortedMenuLinks.push({
        text: "Relatórios",
        href: "#",
        submenus: [
            { text: "Relatório Semanal", href: "/followup" },
            { text: "Relatório Consolidado", href: "/reports" },
            { text: "Integrações", href: "/reports/integration" }
        ],
    });

    if (account?.grantFinancialAccess) {
        arrSortedMenuLinks
            .find((item) => item.text === "Relatórios")
            .submenus.push({ text: "Financeiro", href: "/financial" });
    }

    if(user?.id_organizacoes === 80 ){
        arrSortedMenuLinks
            .find((item) => item.text === "Relatórios")
            .submenus.push({ text: "Ocupação de agenda", href: "/schedule-occupation" });
    }

    if (account?.organization?.owner) {
        arrSortedMenuLinks.push({
            text: "Configurações",
            href: "#",
            submenus: [
                { text: "Perfil", href: "/organization-profile" },
                { text: "Usuários", href: "/users/list" },
                { text: "Integrações", href: "/integrations" },
                { text:"Mensagens", href: "/messages"}
            ],
        });
    } else if (!account?.organization?.owner && account?.grantUserManagementAccess) {
        arrSortedMenuLinks.push({
            text: "Configurações",
            href: "#",
            submenus: [{ text: "Usuários", href: "/users/list" }, { text:"Mensagens", href: "/messages"}]
        });
    }

    return arrSortedMenuLinks;
};

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ children, hideMenu = false, hideUser = false }) => {
    const router = useRouter();
    const { user, logout, account } = useAuth();

    const [hideMobileMenu, setHideMobileMenu] = useState(true);

    const arrSortedMenuLinks = getMenus(user, account);

    // if (account && account?.organization?.owner) {
    //     arrSortedMenuLinks.push({
    //         text: "Configurações",
    //         href: "#",
    //         submenus: [
    //             { text: "Perfil", href: "/organization-profile" },
    //             { text: "Usuários", href: "/users/list" },
    //             { text: "Integrações", href: "/integrations" },
    //         ],
    //     });
    // }

    const onHideMenu = () => {
        setHideMobileMenu((o) => !o);
    };

    return (
        <>
            <Container className={children ? "has-submenu" : ""}>
                <div className="header-lg">
                    <div className="container-logo pb-3">
                        <MobileMenu className="d-md-none" onClick={onHideMenu}>
                            <RxHamburgerMenu />
                        </MobileMenu>
                        <Link href="/dashboard">
                            <a className="logo">
                                <img alt="logo laranja fotop" src="/images/logo-fotop-laranja.svg" />
                            </a>
                        </Link>
                    </div>

                    {user ? (
                        <>
                            <div className="d-none d-md-block text-center pb-2">
                                {hideMenu ? (
                                    ""
                                ) : (
                                    <div className="menu pb-3">
                                        <ul>
                                            {arrSortedMenuLinks.map((item, index) => (
                                                <MenuItem key={index}>
                                                    <a
                                                        key={index}
                                                        href={item.href}
                                                        className={router.pathname === item.href ? "current" : ""}
                                                    >
                                                        {item.text}
                                                    </a>

                                                    {item.submenus ? (
                                                        <Submenu className="submenu">
                                                            <ul>
                                                                {item.submenus?.map((item) => (
                                                                    <li key={item.text}>
                                                                        <a href={item.href}>{item.text}</a>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </Submenu>
                                                    ) : (
                                                        ""
                                                    )}
                                                </MenuItem>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <div className="text-right pb-2">
                                {hideUser ? (
                                    ""
                                ) : (
                                    <div className="menu-side-right menu" style={{ minWidth: "200px" }}>
                                        <ProfileMenu user={user} account={account} onLogoutClick={logout} />
                                    </div>
                                )}
                            </div>
                        </>
                    ) : (
                        ""
                    )}
                </div>
                <div className="border d-lg-none"></div>
                {hideMobileMenu ? (
                    ""
                ) : (
                    <>
                        {user ? (
                            <div className="text-center pt-3 pb-3 d-lg-none">
                                {hideMenu ? (
                                    ""
                                ) : (
                                    <MobileMenuItems className="menu">
                                        <ul>
                                            {arrSortedMenuLinks.map((item, index) => {
                                                if (item.submenus?.length) {
                                                    return item.submenus?.map((item, indexSub) => (
                                                        <li>
                                                            <a href={item.href} key={`sub-${index}-${indexSub}`}>
                                                                {item.text}
                                                            </a>
                                                        </li>
                                                    ));
                                                }

                                                return (
                                                    <li key={index}>
                                                        <a
                                                            key={index}
                                                            href={item.href}
                                                            className={router.pathname === item.href ? "current" : ""}
                                                        >
                                                            {item.text}
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </MobileMenuItems>
                                )}
                            </div>
                        ) : (
                            ""
                        )}
                    </>
                )}
                {children ? (
                    <ContextSubmenu>
                        <ul>{children}</ul>
                    </ContextSubmenu>
                ) : (
                    ""
                )}
            </Container>
        </>
    );
};

export default DashboardHeader;

import React, { createContext, useCallback, useContext, useState } from "react";
import Toast from "~/components/Toast";
import { v4 } from "uuid";

export interface ToastMessages {
    id: string;
    type?: "success" | "error" | "info";
    title: string;
    description?: string;
}

interface ToastContextData {
    addToast(message: Omit<ToastMessages, "id">): void;
    addError(message: Omit<ToastMessages, "id" | "type">): void;
    addSuccess(message: Omit<ToastMessages, "id" | "type">): void;
    removeToast(id: string): void;
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

const ToastProvider = ({ children }) => {
    const [messages, setMessages] = useState<ToastMessages[]>([]);

    const addToast = useCallback(({ type, title, description }: Omit<ToastMessages, "id">) => {
        const id = v4();

        const toast = { id, type, title, description };

        setMessages(state => [...state, toast]);
    }, []);

    const addError = useCallback(
        ({ title, description }: Omit<ToastMessages, "id" | "type">) => {
            addToast({ type: "error", title, description });
        },
        [addToast]
    );

    const addSuccess = useCallback(
        ({ title, description }: Omit<ToastMessages, "id" | "type">) => {
            addToast({ type: "success", title, description });
        },
        [addToast]
    );

    const removeToast = useCallback((id: string) => {
        setMessages(state => state.filter(message => message.id !== id));
    }, []);

    return (
        <ToastContext.Provider value={{ addToast, addError, addSuccess, removeToast }}>
            {children}
            <Toast messages={messages} />
        </ToastContext.Provider>
    );
};

const useToast = (): ToastContextData => {
    const context = useContext(ToastContext);

    if (!context) {
        throw new Error("useToast must be used within an ToastProvider");
    }
    return context;
};

export { ToastProvider, useToast };

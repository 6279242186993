import { useState } from "react";
import Link from "next/link";
import styled from "styled-components";
import OutsideAlerter from "~/shared/OutsideAlerter";
import api from "~/adapters/api";
import useAuth from "~/hooks/useAuth";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .user-info {
        position: relative;
        width: 100%;
    }

    .user-info .user-fullname {
        color: #666;
        font-weight: 500;
        font-size: 16px;
        margin-right: 0px !important;
    }

    .user-info .user-company {
        color: #999;
        font-weight: 500;
        font-size: 12px;
        margin-right: 0px !important;
    }

    .user-menu {
        display: block;
        position: absolute;
        right: 0;
        width: 100%;
        max-width: 200px;
        background: #fff;
        margin-top: 10px;
        border-top: 5px solid #fe8418;
        padding: 20px 10px 0 10px;
        z-index: 50;
    }

    .user-menu ol {
        display: block;
        width: 100%;
        padding: 0;
    }

    .user-menu ol li {
        width: 100%;
        text-align: left;
        padding: 5px 0;
    }

    .user-menu a, .user-menu button {
        width: 100%;
        display: inline-block;
        margin: 0 !important;
    }

    .separator {
        background: #dcdcdc;
        height: 2px;
        margin: 0;
        padding: 0;
    }

    button {
        color: #9d9d9d;
        background: none;
        text-align: left;
        padding: 5px 5px;
        font-size: 1rem;
        border-bottom: 2px solid transparent;
    }

    button:hover {
        border-bottom: 2px solid #fe8418;
        color: #000 !important;
        font-weight: 500;
        cursor: pointer;
    }
`;

const ProfileMenu = ({ user, account, onLogoutClick }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const { changeProfileToken } = useAuth();

    const clickOut = () => {
        setMenuOpen(false);
    };

    const changeProfile = (profile) => {
        api.organizations
            .users
            .changeProfile({ profile })
            .then(async ({ token }) => {
                changeProfileToken(token).then(() => {
                    location.href = "/marketplace/dashboard";
                });
            });
    };

    return (
        <Container className="profile-menu">
            <div className="user-info mr-8" onMouseOver={() => setMenuOpen(true)}>
                <span className="user-fullname">{user.nome}</span>

                <br />
                <span className="user-company">
                    {account?.organization ? account?.organization?.name : "-"}
                </span>

                {menuOpen ? (
                    <OutsideAlerter
                        className="user-menu"
                        onOutside={clickOut}
                        style={{ minWidth: "200px" }}
                    >
                        <ol>
                            {account?.organization?.id && account?.organization?.id !== 56 ? (
                                <li>
                                    <button onClick={() => changeProfile("marketplace")}>
                                        Acessar Marketplace
                                    </button>
                                </li>
                            ) : (
                                ""
                            )}
                            <li>
                                <Link href="/profile/change-password">
                                    <a>Alterar senha</a>
                                </Link>
                            </li>
                            <li className="logout-action">
                                <a href="#" rel="button" onClick={onLogoutClick}>Sair</a>
                            </li>
                        </ol>
                    </OutsideAlerter>
                ) : (
                    ""
                )}
            </div>
        </Container>
    );
}

export default ProfileMenu;

import React, { useEffect, createContext, useCallback, useContext, useState } from "react";

export interface LgpdNotifierState {
    showNotification: boolean;
    hideNotification: () => void;
}

const LgpdNotifierContext = createContext<LgpdNotifierState>({ showNotification: false } as LgpdNotifierState);

function setCookie(cname: string, cvalue: string) {
    document.cookie = cname + "=" + cvalue + ";path=/";
}

function getCookie(cname: string) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

const LgpdNotifierProvider = ({ children }) => {
    const [showNotification, setShowNotification] = useState(false);

    useEffect(() => {
        const lgpdData = getCookie("fotopServicos:lgpd");
        if (!lgpdData) {
            setShowNotification(true);
        }
    }, [])

    const hideNotification = useCallback(() => {
        try {
            setCookie("fotopServicos:lgpd", new Date().toString());
            setShowNotification(false);
        } catch (e) {
            console.log(e);
        }
    }, []);

    return (
        <LgpdNotifierContext.Provider value={{ showNotification, hideNotification }}>
            {children}
        </LgpdNotifierContext.Provider>
    );
};

const useLgpdNotifier = (): LgpdNotifierState => {
    const context = useContext(LgpdNotifierContext);

    if (!context) {
        throw new Error("LgpdNotifierContext invalid");
    }

    return context;
};

export { LgpdNotifierProvider, useLgpdNotifier };

import styled from "styled-components";

const Spacing = styled.div`
    height: 100%;

    .m-4 {
        margin: 4px;
    }

    .mx-4 {
        margin-right: 4px;
        margin-left: 4px;
    }

    .my-4 {
        margin-top: 4px;
        margin-bottom: 4px;
    }

    .nt-5 {
        margin-top: -5px;
    }

    .nt-10 {
        margin-top: -10px;
    }

    .nt-20 {
        margin-top: -20px;
    }

    .mt-4 {
        margin-top: 4px;
    }

    .mt-5 {
        margin-top: 5px;
    }

    .mt-10 {
        margin-top: 10px;
    }

    .mt-20 {
        margin-top: 20px;
    }

    .mt-30 {
        margin-top: 30px;
    }

    .mt-40 {
        margin-top: 40px;
    }

    .mt-50 {
        margin-top: 50px;
    }

    .mt-150 {
        margin-top: 150px;
    }

    .ml-4 {
        margin-left: 4px;
    }

    .ml-15 {
        padding-left: 15px;
    }

    .ml-10 {
        margin-left: 10px;
    }

    .ml-20 {
        margin-left: 20px;
    }

    .ml-30 {
        margin-left: 30px;
    }

    .ml-40 {
        margin-left: 40px;
    }

    .ml-50 {
        margin-left: 50px;
    }

    .ml-60 {
        margin-left: 60px;
    }

    .ml-70 {
        margin-left: 70px;
    }

    .ml-80 {
        margin-left: 80px;
    }

    .ml-90 {
        margin-left: 90px;
    }

    .ml-100 {
        margin-left: 100px;
    }

    .mb-4 {
        margin-bottom: 4px;
    }

    .mb-5 {
        margin-bottom: 5px;
    }

    .mb-10 {
        margin-bottom: 10px;
    }

    .mb-20 {
        margin-bottom: 20px;
    }

    .mb-30 {
        margin-bottom: 30px;
    }

    .mb-50 {
        margin-bottom: 50px;
    }

    .mr-4 {
        margin-right: 4px;
    }

    .mr-8 {
        margin-right: 8px;
    }

    .mr-10 {
        margin-right: 10px;
    }

    .mr-20 {
        margin-right: 20px;
    }

    .p-4 {
        padding: 4px;
    }

    .px-4 {
        padding-right: 4px;
        padding-left: 4px;
    }

    .py-4 {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .pl-5 {
        padding-left: 5px;
    }

    .pl-10 {
        padding-left: 10px;
    }

    .pl-10 {
        padding-left: 10px;
    }

    .pl-15 {
        padding-left: 15px;
    }

    .pl-20 {
        padding-left: 20px;
    }

    .pl-25 {
        padding-left: 25px;
    }

    .pl-30 {
        padding-left: 30px;
    }

    .pl-40 {
        padding-left: 40px;
    }

    .pl-50 {
        padding-left: 50px;
    }

    .pl-60 {
        padding-left: 60px;
    }

    .pl-70 {
        padding-left: 70px;
    }

    .pl-80 {
        padding-left: 80px;
    }

    .pl-90 {
        padding-left: 90px;
    }

    .pl-100 {
        padding-left: 100px;
    }

    .pt-5 {
        padding-top: 5px;
    }

    .pt-10 {
        padding-top: 10px;
    }

    .pt-15 {
        padding-top: 15px;
    }

    .pt-20 {
        padding-top: 20px;
    }

    .pt-25 {
        padding-top: 25px;
    }

    .pt-30 {
        padding-top: 30px;
    }

    .pt-35 {
        padding-top: 35px;
    }

    .pt-40 {
        padding-top: 40px;
    }

    .pt-45 {
        padding-top: 45px;
    }

    .pt-50 {
        padding-top: 50px;
    }

    .pt-60 {
        padding-top: 60px;
    }

    .pt-70 {
        padding-top: 70px;
    }

    .pt-150 {
        padding-top: 150px;
    }

    .pb-5 {
        padding-bottom: 5px;
    }

    .pb-10 {
        padding-bottom: 10px;
    }

    .pb-15 {
        padding-bottom: 15px;
    }

    .pb-20 {
        padding-bottom: 20px;
    }

    .pb-25 {
        padding-bottom: 25px;
    }

    .pb-30 {
        padding-bottom: 30px;
    }

    .pb-40 {
        padding-bottom: 40px;
    }

    .pb-50 {
        padding-bottom: 50px;
    }

    .pr-5 {
        padding-right: 5px !important;
    }

    .pr-10 {
        padding-right: 10px !important;
    }

    .pr-15 {
        padding-right: 15px !important;
    }

    .pr-20 {
        padding-right: 20px !important;
    }

    .pr-30 {
        padding-right: 30px !important;
    }

    .pr-40 {
        padding-right: 40px !important;
    }

    .pr-50 {
        padding-right: 50px !important;
    }

    .pr-60 {
        padding-right: 60px !important;
    }

    .align-center {
        justify-content: center !important;
    }

    .justify-content-end {
        display: flex !important;
        justify-content: flex-end !important;
    }

    @media (min-width: 250px) {
        .break-xs-line {
            display: block;
        }

        .mt-xs-10 {
            margin-top: 10px;
        }

        .ml-xs-none {
            margin-left: 0 !important;
        }
    }

    @media (max-width: 360px) {
        .pt-xs-10 {
            padding-top: 10px;
        }
    }

    @media (min-width: 360px) {
        .none-break-line {
            display: inline;
        }
    }

    @media (min-width: 920px) {
        .mt-lg-10 {
            margin-top: 10px;
        }

        .break-lg-line {
            display: block;
        }

        .align-lg-center {
            justify-content: center !important;
        }
    }

    @media (min-width: 1200px) {
        .align-lg-center {
            justify-content: center !important;
        }

        .pl-lg-5 {
            margin-left: 5px !important;
        }

        .pl-lg-60 {
            margin-left: 60px !important;
        }

        .pb-lg-50 {
            margin-bottom: 50px !important;
        }
    }

    @media (min-width: 1300px) {
        .none-break-xl-line {
            display: inline;
        }

        .ml-xl-10 {
            margin-left: 10px !important;
        }
    }
`;

export default Spacing;
